<template>
  <div id="defenseGame" class="has-text-black-bis" :style="{ '--mainColor': options.controllerBackgroundColor }">
    <!-- HOW TO PLAY -->
    <section class="section p-0" v-if="serverState < 2">
      <div class="waiting is-flex is-flex-direction-column has-background-white-ter has-text-black-bis">
        <div class="top p-5 is-flex is-flex-direction-column  is-justify-content-space-around">
          <div class="mb-5 has-text-weight-bold">{{ $t('exp_defense.howToPlay') }}</div>
          <div class="tutorial p-5 is-flex is-justify-content-center">
            <img src="/assets/defense/tutorial.png" alt="How to play" class="image" />
          </div>
          <p>{{ options.tutorial }}</p>
        </div>
        <div class="bottom is-flex is-align-items-center is-justify-content-center">
          <span class="is-size-5">{{ $t('exp_defense.waitingPlayers') }}</span>
        </div>
      </div>
    </section>

    <!-- GAME STARTED -->
    <section class="game-started playing section p-0 is-justify-content-space-around" v-if="serverState == 2"
      :style="{ backgroundImage: bgControllerUrl }">
      <template v-if="!joystickEnabled">
        <!-- empty div spacer -->
        <div v-if="options.ui.logo"></div>

        <div class="big-card has-text-centered">
          <div class="card-top p-5">
            <div class="gamepad">
              <d-icon icon="FaGamepad" size="is-large" />
            </div>
            <div class="is-size-4 mb-2 has-text-weight-bold">{{ $t('exp_defense.helpFighting') }}</div>
            <div class="is-size-6 pb-5">{{ $t('exp_defense.getReady') }}</div>
          </div>
          <div class="card-bottom">
            
          </div>
        </div>

        <img class="logo" v-if="options.ui.logo" :src="options.ui.logo.url">
      </template>
      <template v-else>
        <!-- empty div spacer -->
        <div v-if="options.ui.logo"></div>
        <div class="joystick-wrapper">
          <JoyStick @leverchange="UpdateJoystick($event)" @touchStart="TouchStart" :debug="ShowDebugger"
            :isCircular="true" :centerColor="options.controllerBackgroundColor" :startingAngle="initialRandomAngle" />
        </div>
        <img class="logo" v-if="options.ui.logo" :src="options.ui.logo.url">
      </template>
    </section>


    <!-- GAME FINISHED -->
    <section v-if="(serverState == 3)" :style="{ backgroundImage: bgControllerUrl }"
      class="match-finished section p-5 has-text-white-bis">
      <!-- Scoreboard -->
      <div class="is-flex is-flex-direction-column has-text-centered is-justify-content-space-around">
        <div></div> <!-- flex spacer div -->
        <div v-if="result.win">
          <div class="is-size-2">
            <!-- check si gano -->
            <svg class="win" viewBox="0 0 512 512">
              <path
                d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            <template v-if="options.finalScreen.winText">{{ options.finalScreen.winText }}</template>
            <template v-else>{{ $t('exp_external.youWin') }}</template>
          </div>
          <div v-if="options.finalScreen.winTextBelow" class="is-size-4">{{ options.finalScreen.winTextBelow }}</div>
          <p class="score is-size-5 has-text-weight-bold">{{ $t('exp_external.yourScore') }}</p>
          <p class="is-size-5 has-text-weight-bold">{{ result.teamScore }}</p>
        </div>
        <div v-else-if="result.win != undefined">
          <div class="is-size-2">
            <!-- cruz si perdio -->
            <svg class="lose" viewBox="0 0 320 512">
              <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            <template v-if="options.finalScreen.lostText">{{ options.finalScreen.lostText }} </template>
            <template v-else>{{ $t('exp_external.youLost') }} </template>
          </div>
          <div v-if="options.finalScreen.lostTextBelow" class="is-size-4">{{ options.finalScreen.lostTextBelow }}</div>
          <p class="score is-size-5 has-text-weight-bold">{{ $t('exp_external.yourScore') }}</p>
          <p class="is-size-5 has-text-weight-bold">{{ result.teamScore }}</p>
        </div>

        <b-button type="is-primary" :label="$t('system.backToMenu')" expanded @click="$router.push('/')" />

      </div>
    </section>

  </div>
</template>

<script>
import JoyStick from "../externals/JoyStick"

export default {
  components: {
    JoyStick
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      EmitCoolingDown: false,
      EmitCooldownTime: 100, // ms
      initialRandomAngle: 0,
      joystickData: {
        x: 0,
        y: 0,
        speed: 0,
        angle: 0,
        radians: 0,
      },
      joystickEnabled: false,
      result: {
        win: false,
        gameTime: '',
        teamScore: 0,
      }
    };
  },
  computed: {
    hasQueue() {
      return this.$parent.room.experience.component[0].queue.enabled
    },
    serverState() {
      return this.$parent.serverState
    },
    bgControllerUrl() {
      return this.options.controllerBackground ?
        'url(' + encodeURI(
          this.options.controllerBackground.sizes.mobile.url ?
            this.options.controllerBackground.sizes.mobile.url :
            this.options.controllerBackground.url) + ')'
        : ''
    },
    ShowDebugger() {
      return !this.$store.state.setup.others?.hideDebugger
    },
  },
  methods: {
    TouchStart() {
      this.$socket.client.emit('gameMessage', {
        type: 'TouchStart',
      });
    },
    UpdateJoystick({ x, y, speed, angle, radians }) {
      //Copia los datos del joystick del componente hijo
      const joy = this.joystickData;
      joy.x = x;
      joy.y = y;
      joy.speed = speed;
      joy.angle = angle;
      joy.radians = radians;

      // Envia los datos a la screen
      if (this.EmitCoolingDown) return  // cooldown
      this.$socket.client.emit('gameMessage', {
        type: 'GetJoystickData', // me gustaria cambiar esto a 'pos' para que sea igual al gyro y otros joysticks
        x: joy.x,
        y: joy.y,
        speed: joy.speed,
        angle: joy.angle,
        radians: joy.radians
      });
      this.EmitCoolingDown = true;
      setTimeout(() => {
        this.EmitCoolingDown = false
      }, this.EmitCooldownTime)
    },
  },
  mounted() {
    this.initialRandomAngle = Math.random() * 360;
  },

  sockets: {
    enableJoystick() {
      this.joystickEnabled = true
    },
    vibrate(data) {
      navigator.vibrate(data.vibrateAmount)
    },
    showResult(data) {
      console.log(data)
      console.log(data.winner)
      console.log(data.gameTime)
      console.log(data.teamScore)

      if (this.hasQueue) {
        this.result.win = data.winner;
        this.result.teamScore = data.teamScore;
        let minutes = Math.floor(data.gameTime / 60)
        let seconds = data.gameTime - (minutes * 60)
        if (minutes < 10) minutes = "0" + minutes
        if (seconds < 10) seconds = "0" + seconds
        this.result.gameTime = minutes + ':' + Math.floor(Math.floor(seconds))

        if (this.result.win) {
          this.$sfxPlay('winnerscreen')
        } else if (typeof this.result.win != undefined) {
          this.$sfxPlay('loserscreen')
        } else {
          this.$sfxPlay('tiescreen')
        }

      } else {
        this.$sfxPlay('finalscreen')
      }

    },
  },
};
</script>

<!-- no scope style -->
<style>
.joystick-wrapper {
  background: none !important;
}
</style>

<!-- scope style -->
<style scoped lang="scss">
@import '@/styles/variables.scss';

section {
  height: 100%;
}

.game-started {
  background-color: var(--mainColor);
}

.big-card {
        border-radius: $general-border-radius;
        overflow: hidden;
        color: $system-queue-text-color;
        width: calc(100% - 3rem);
    }

    .gamepad {
        color: $system-queue-hourglass;
    }

    .card-top {
        background-color: $system-queue-card-top;

        .is-size-4 {
            max-width: 230px;
            margin-inline: auto;
            line-height: 1;
        }
    }

    .card-bottom {
        background-color: $system-queue-card-bottom;
    }

.playing {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;

  .joystick-wrapper {
    width: 86vw;
    padding: 11%;
    background-size: contain;
    background-repeat: no-repeat;
  }

}

.auto-height {
  height: auto !important;
}

.tutorial img {
  height: 250px;
}

.waiting {
  height: 100%;

  .top {
    height: 100%;
    line-height: 1;
  }

  .bottom {
    background-color: $trivia-waiting-players-background;
    color: $trivia-footer-color;
    height: 93px;
  }

}

img.logo {
  max-width: 150px;
}

.match-finished {
  background: var(--mainColor);
  background-size: cover;

  &>div {
    height: 100%;
    gap: 3em;
  }

  svg {
    height: 4rem;
  }

  .lose {
    // fill: $trivia-danger;
    fill: #fff;
  }

  .win {
    fill: #fff;
  }

  .score {
    margin-top: 5rem;
  }

}

.hasBackground {

  .match-finished {
    color: $white-ter;



  }

}

.hasNotBackground {

  .match-finished {
    color: $black-ter;

    .win {
      fill: $trivia-sucess;
    }

  }

}
</style>